//import XLSX from 'xlsx';
import XLSX from 'xlsx-js-style';

const printExcel = (data: any, filename: string) => {
  const sheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();

  const colNames = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1'];
  for (const itm of colNames) {
    sheet[itm].s = { fill: { fgColor: { rgb: "FFFF00" } }, font: { bold: true } };
  }

  sheet['!cols'] = fitToColumn(data);

  XLSX.utils.book_append_sheet(workbook, sheet, filename);
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

const fitToColumn = (data: any) => {
  const columnWidths = [];
  
  for (const property in data[0]) {
    columnWidths.push({ wch: Math.max(property ? property.toString().length : 0, ...data.map((obj: any) => obj[property] ? obj[property].toString().length : 0)) + 1 });
  }

  return columnWidths;
};

export default printExcel;
