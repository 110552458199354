export const countries = [
  { label: 'Afganistán	', code: 'AF' },
  { label: 'Albania	', code: 'AL' },
  { label: 'Alemania	', code: 'DE' },
  { label: 'Andorra	', code: 'AD' },
  { label: 'Angola	', code: 'AO' },
  { label: 'Anguila	', code: 'AI' },
  { label: 'Antártida	', code: 'AQ' },
  { label: 'Antigua y Barbuda	', code: 'AG' },
  { label: 'Antillas Neerlandesas	', code: 'AN' },
  { label: 'Arabia Saudita	', code: 'SA' },
  { label: 'Argel	', code: 'DZ' },
  { label: 'Argentina	', code: 'AR' },
  { label: 'Armenia	', code: 'AM' },
  { label: 'Aruba	', code: 'AW' },
  { label: 'Australia	', code: 'AU' },
  { label: 'Austria	', code: 'AT' },
  { label: 'Azerbaiyán	', code: 'AZ' },
  { label: 'Bahamas	', code: 'BS' },
  { label: 'Bahréin	', code: 'BH' },
  { label: 'Bangladesh	', code: 'BD' },
  { label: 'Barbados	', code: 'BB' },
  { label: 'Belarús	', code: 'BY' },
  { label: 'Bélgica	', code: 'BE' },
  { label: 'Belice	', code: 'BZ' },
  { label: 'Benin	', code: 'BJ' },
  { label: 'Bermudas	', code: 'BM' },
  { label: 'Bhután	', code: 'BT' },
  { label: 'Bolivia	', code: 'BO' },
  { label: 'Bosnia y Herzegovina	', code: 'BA' },
  { label: 'Botsuana	', code: 'BW' },
  { label: 'Brasil	', code: 'BR' },
  { label: 'Brunéi	', code: 'BN' },
  { label: 'Bulgaria	', code: 'BG' },
  { label: 'Burkina Faso	', code: 'BF' },
  { label: 'Burundi	', code: 'BI' },
  { label: 'Cabo Verde	', code: 'CV' },
  { label: 'Camboya	', code: 'KH' },
  { label: 'Camerún	', code: 'CM' },
  { label: 'Canadá	', code: 'CA' },
  { label: 'Chad	', code: 'TD' },
  { label: 'Chile	', code: 'CL' },
  { label: 'China	', code: 'CN' },
  { label: 'Chipre	', code: 'CY' },
  { label: 'Ciudad del Vaticano	', code: 'VA' },
  { label: 'Colombia	', code: 'CO' },
  { label: 'Comoros	', code: 'KM' },
  { label: 'Congo	', code: 'CG' },
  { label: 'Corea del Norte	', code: 'KP' },
  { label: 'Corea del Sur	', code: 'KR' },
  { label: 'Costa de Marfil	', code: 'CI' },
  { label: 'Costa Rica	', code: 'CR' },
  { label: 'Croacia	', code: 'HR' },
  { label: 'Cuba	', code: 'CU' },
  { label: 'Dinamarca	', code: 'DK' },
  { label: 'Domínica	', code: 'DM' },
  { label: 'Ecuador	', code: 'EC' },
  { label: 'Egipto	', code: 'EG' },
  { label: 'El Salvador	', code: 'SV' },
  { label: 'Emiratos Árabes Unidos	', code: 'AE' },
  { label: 'Eritrea	', code: 'ER' },
  { label: 'Eslovaquia	', code: 'SK' },
  { label: 'Eslovenia	', code: 'SI' },
  { label: 'España	', code: 'ES' },
  { label: 'Estados Unidos de América	', code: 'US' },
  { label: 'Estonia	', code: 'EE' },
  { label: 'Etiopía	', code: 'ET' },
  { label: 'Fiji	', code: 'FJ' },
  { label: 'Filipinas	', code: 'PH' },
  { label: 'Finlandia	', code: 'FI' },
  { label: 'Francia	', code: 'FR' },
  { label: 'Gabón	', code: 'GA' },
  { label: 'Gambia	', code: 'GM' },
  { label: 'Georgia	', code: 'GE' },
  { label: 'Georgia del Sur e Islas Sandwich del Sur	', code: 'GS' },
  { label: 'Ghana	', code: 'GH' },
  { label: 'Gibraltar	', code: 'GI' },
  { label: 'Granada	', code: 'GD' },
  { label: 'Grecia	', code: 'GR' },
  { label: 'Groenlandia	', code: 'GL' },
  { label: 'Guadalupe	', code: 'GP' },
  { label: 'Guam	', code: 'GU' },
  { label: 'Guatemala	', code: 'GT' },
  { label: 'Guayana	', code: 'GY' },
  { label: 'Guayana Francesa	', code: 'GF' },
  { label: 'Guernsey	', code: 'GG' },
  { label: 'Guinea	', code: 'GN' },
  { label: 'Guinea Ecuatorial	', code: 'GQ' },
  { label: 'Guinea-Bissau	', code: 'GW' },
  { label: 'Haití	', code: 'HT' },
  { label: 'Honduras	', code: 'HN' },
  { label: 'Hong Kong	', code: 'HK' },
  { label: 'Hungría	', code: 'HU' },
  { label: 'India	', code: 'IN' },
  { label: 'Indonesia	', code: 'ID' },
  { label: 'Irak	', code: 'IQ' },
  { label: 'Irán	', code: 'IR' },
  { label: 'Irlanda	', code: 'IE' },
  { label: 'Isla Bouvet	', code: 'BV' },
  { label: 'Isla de Man	', code: 'IM' },
  { label: 'Islandia	', code: 'IS' },
  { label: 'Islas Áland	', code: 'AX' },
  { label: 'Islas Caimán	', code: 'KY' },
  { label: 'Islas Christmas	', code: 'CX' },
  { label: 'Islas Cocos	', code: 'CC' },
  { label: 'Islas Cook	', code: 'CK' },
  { label: 'Islas Faroe	', code: 'FO' },
  { label: 'Islas Heard y McDonald	', code: 'HM' },
  { label: 'Islas Malvinas	', code: 'FK' },
  { label: 'Islas Marshall	', code: 'MH' },
  { label: 'Islas Norkfolk	', code: 'NF' },
  { label: 'Islas Palaos	', code: 'PW' },
  { label: 'Islas Pitcairn	', code: 'PN' },
  { label: 'Islas Solomón	', code: 'SB' },
  { label: 'Islas Svalbard y Jan Mayen	', code: 'SJ' },
  { label: 'Islas Turcas y Caicos	', code: 'TC' },
  { label: 'Islas Vírgenes Británicas	', code: 'VG' },
  { label: 'Islas Vírgenes de los Estados Unidos de América	', code: 'VI' },
  { label: 'Israel	', code: 'IL' },
  { label: 'Italia	', code: 'IT' },
  { label: 'Jamaica	', code: 'JM' },
  { label: 'Japón	', code: 'JP' },
  { label: 'Jersey	', code: 'JE' },
  { label: 'Jordania	', code: 'JO' },
  { label: 'Kazajstán	', code: 'KZ' },
  { label: 'Kenia	', code: 'KE' },
  { label: 'Kirguistán	', code: 'KG' },
  { label: 'Kiribati	', code: 'KI' },
  { label: 'Kuwait	', code: 'KW' },
  { label: 'Laos	', code: 'LA' },
  { label: 'Lesotho	', code: 'LS' },
  { label: 'Letonia	', code: 'LV' },
  { label: 'Líbano	', code: 'LB' },
  { label: 'Liberia	', code: 'LR' },
  { label: 'Libia	', code: 'LY' },
  { label: 'Liechtenstein	', code: 'LI' },
  { label: 'Lituania	', code: 'LT' },
  { label: 'Luxemburgo	', code: 'LU' },
  { label: 'Macao	', code: 'MO' },
  { label: 'Macedonia	', code: 'MK' },
  { label: 'Madagascar	', code: 'MG' },
  { label: 'Malasia	', code: 'MY' },
  { label: 'Malawi	', code: 'MW' },
  { label: 'Maldivas	', code: 'MV' },
  { label: 'Mali	', code: 'ML' },
  { label: 'Malta	', code: 'MT' },
  { label: 'Marruecos	', code: 'MA' },
  { label: 'Martinica	', code: 'MQ' },
  { label: 'Mauricio	', code: 'MU' },
  { label: 'Mauritania	', code: 'MR' },
  { label: 'Mayotte	', code: 'YT' },
  { label: 'México	', code: 'MX' },
  { label: 'Micronesia	', code: 'FM' },
  { label: 'Moldova	', code: 'MD' },
  { label: 'Mónaco	', code: 'MC' },
  { label: 'Mongolia	', code: 'MN' },
  { label: 'Montenegro	', code: 'ME' },
  { label: 'Montserrat	', code: 'MS' },
  { label: 'Mozambique	', code: 'MZ' },
  { label: 'Myanmar	', code: 'MM' },
  { label: 'Namibia	', code: 'NA' },
  { label: 'Nauru	', code: 'NR' },
  { label: 'Nepal	', code: 'NP' },
  { label: 'Nicaragua	', code: 'NI' },
  { label: 'Níger	', code: 'NE' },
  { label: 'Nigeria	', code: 'NG' },
  { label: 'Niue	', code: 'NU' },
  { label: 'Noruega	', code: 'NO' },
  { label: 'Nueva Caledonia	', code: 'NC' },
  { label: 'Nueva Zelanda	', code: 'NZ' },
  { label: 'Omán	', code: 'OM' },
  { label: 'Países Bajos	', code: 'NL' },
  { label: 'Pakistán	', code: 'PK' },
  { label: 'Palestina	', code: 'PS' },
  { label: 'Panamá	', code: 'PA' },
  { label: 'Papúa Nueva Guinea	', code: 'PG' },
  { label: 'Paraguay	', code: 'PY' },
  { label: 'Perú	', code: 'PE' },
  { label: 'Polinesia Francesa	', code: 'PF' },
  { label: 'Polonia	', code: 'PL' },
  { label: 'Portugal	', code: 'PT' },
  { label: 'Puerto Rico	', code: 'PR' },
  { label: 'Qatar	', code: 'QA' },
  { label: 'Reino Unido	', code: 'GB' },
  { label: 'República Centro-Africana	', code: 'CF' },
  { label: 'República Checa	', code: 'CZ' },
  { label: 'República Dominicana	', code: 'DO' },
  { label: 'Reunión	', code: 'RE' },
  { label: 'Ruanda	', code: 'RW' },
  { label: 'Rumanía	', code: 'RO' },
  { label: 'Rusia	', code: 'RU' },
  { label: 'Sahara Occidental	', code: 'EH' },
  { label: 'Samoa	', code: 'WS' },
  { label: 'Samoa Americana	', code: 'AS' },
  { label: 'San Bartolomé	', code: 'BL' },
  { label: 'San Cristóbal y Nieves	', code: 'KN' },
  { label: 'San Marino	', code: 'SM' },
  { label: 'San Pedro y Miquelón	', code: 'PM' },
  { label: 'San Vicente y las Granadinas	', code: 'VC' },
  { label: 'Santa Elena	', code: 'SH' },
  { label: 'Santa Lucía	', code: 'LC' },
  { label: 'Santo Tomé y Príncipe	', code: 'ST' },
  { label: 'Senegal	', code: 'SN' },
  { label: 'Serbia y Montenegro	', code: 'RS' },
  { label: 'Seychelles	', code: 'SC' },
  { label: 'Sierra Leona	', code: 'SL' },
  { label: 'Singapur	', code: 'SG' },
  { label: 'Siria	', code: 'SY' },
  { label: 'Somalia	', code: 'SO' },
  { label: 'Sri Lanka	', code: 'LK' },
  { label: 'Suazilandia	', code: 'SZ' },
  { label: 'Sudáfrica	', code: 'ZA' },
  { label: 'Sudán	', code: 'SD' },
  { label: 'Suecia	', code: 'SE' },
  { label: 'Suiza	', code: 'CH' },
  { label: 'Surinam	', code: 'SR' },
  { label: 'Tailandia	', code: 'TH' },
  { label: 'Taiwán	', code: 'TW' },
  { label: 'Tanzania	', code: 'TZ' },
  { label: 'Tayikistán	', code: 'TJ' },
  { label: 'Territorio Británico del Océano Índico	', code: 'IO' },
  { label: 'Territorios Australes Franceses	', code: 'TF' },
  { label: 'Timor-Leste	', code: 'TL' },
  { label: 'Togo	', code: 'TG' },
  { label: 'Tokelau	', code: 'TK' },
  { label: 'Tonga	', code: 'TO' },
  { label: 'Trinidad y Tobago	', code: 'TT' },
  { label: 'Túnez	', code: 'TN' },
  { label: 'Turkmenistán	', code: 'TM' },
  { label: 'Turquía	', code: 'TR' },
  { label: 'Tuvalu	', code: 'TV' },
  { label: 'Ucrania	', code: 'UA' },
  { label: 'Uganda	', code: 'UG' },
  { label: 'Uruguay	', code: 'UY' },
  { label: 'Uzbekistán	', code: 'UZ' },
  { label: 'Vanuatu	', code: 'VU' },
  { label: 'Venezuela	', code: 'VE' },
  { label: 'Vietnam	', code: 'VN' },
  { label: 'Wallis y Futuna	', code: 'WF' },
  { label: 'Yemen	', code: 'YE' },
  { label: 'Yibuti	', code: 'DJ' },
];
