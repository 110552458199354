import { Document } from './getDocuments';
import {
  tipoEstadoCompras,
  tipoCFE,
  tipoMoneda,
  tipoPago,
  buildPDFCompras,
  transformDateYYYMMDD,
  WSError,
  getAllComprasURL,
  transformDateTime,
  post,
  WebService
} from './utils';
import { transformDate, formatImporte } from 'utils';
import Axios from 'axios';

const baseURL = 'https://infrasistemas.sytes.net:7080/WebService.asmx?op=';
const urlAceptarComprobante = baseURL + 'Aceptar_Comprobante';

export const getAllCompras = async (
  rut: string,
  id: number,
  isAccountant: boolean,
  desde: string,
  hasta: string
) => {
  const { data } = await Axios.get(
    getAllComprasURL(rut, isAccountant, desde, hasta),
    {
      timeout: 5000,
      responseType: 'json',
    }
  ).catch((error) => {
    throw new WSError('Documentos_Externos');
  });
  return formatDocument(data, id);
};

const formatDocument = (data: any[], id: number): Document[] =>
  data.map((item: any) => {
    let disabled = false;
    const estado = tipoEstadoCompras(item.FEEstadoEntreEmpresas?.trim() ?? '');
    if (estado === 'Error' || estado === 'Incorrecto') {
      disabled = true;
    }
    const newItem: Document = {
      estado,
      // estadoEmpresas: tipoEstadoCompras(item.FEEstadoEntreEmpresas?.trim() ?? ''),
      cfe: tipoCFE(item.FEIDDocTipoCFE),
      RawCfe: item.FEIDDocTipoCFE,
      serie: item.FEIDDocSerie,
      numero: item.FEIDDocNro,
      fecha: transformDate(item.FEIDDocFchEmis),
      cliente: item.FEProveedor,
      importe: formatImporte(item.FETOTMntPagar),
      iva: formatImporte(item.IVA),
      subtotal: formatImporte(item.Subtotal),
      moneda: tipoMoneda(item.FETOTTpoMoneda),
      tipoPago: tipoPago(item.FEIDDocFmaPago),
      tipoCambio: formatImporte(item.TC),
      pdf: buildPDFCompras(
        id,
        transformDateYYYMMDD(item.FEFechaAlta),
        item.FECabID,
        `${item.FEProveedor}-${item.FEIDDocNro}`,
        item.FEHash
      ),
      pdfPayload: {
        id: id,
        docId: item.FECabID,
        fecha: transformDateYYYMMDD(item.FEFechaAlta),
        fechaGetTime: transformDateTime(item.FEFechaAlta),
      },
      disabled,
    };
    return newItem;
  });


export const aceptarComprobante = async (rut: string, FECabID: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <Aceptar_Comprobante xmlns="http://infrasistemas.sytes.net:7080/">
        <RUT>${rut}</RUT>
        <FECabID>${FECabID}</FECabID>
      </Aceptar_Comprobante>
    </soap12:Body>
  </soap12:Envelope>`;

  const aceptarComprobanteWS: WebService<string> = {
    url: urlAceptarComprobante,
    name: 'Aceptar_Comprobante',
    resultSelector: 'Aceptar_ComprobanteResult',
    sr,
    transform: (res) => res,
  };

  return post(aceptarComprobanteWS);
}

export const rechazarComprobante = async (rut: string, FECabID: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <Rechazar_Comprobante xmlns="http://infrasistemas.sytes.net:7080/">
        <RUT>${rut}</RUT>
        <FECabID>${FECabID}</FECabID>
      </Rechazar_Comprobante>
    </soap12:Body>
  </soap12:Envelope>`;

  const aceptarComprobanteWS: WebService<string> = {
    url: urlAceptarComprobante,
    name: 'Rechazar_Comprobante',
    resultSelector: 'Rechazar_ComprobanteResult',
    sr,
    transform: (res) => res,
  };

  return post(aceptarComprobanteWS);
}
