import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Space,
  Button,
  Spin,
  Tooltip,
  Typography,
  Result,
  Modal,
  Alert,
  InputNumber
} from 'antd';
import { datepickerLocale } from 'constants/datepickerLocale';
import { PlusOutlined, DeleteOutlined, BookOutlined } from '@ant-design/icons';
import { AppDispatch, RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { FormProviderProps } from 'antd/lib/form/context';
//import { isArray } from 'util';
import {
  ciRegex,
  rutRegex,
  codRetencionRegex,
  onlyNumbersRegex,
} from 'constants/regex';
import moment from 'moment';
import { formatImporte, round, roundToFour, showToFour, formatDecimal, showToThree } from 'utils';
import { getInterbancario, setInterbancario } from 'store/app';
import { updateRemaining, postDocumentThunk, setPostState } from 'store/docs';
import { MonedaType, updatePreferences } from 'store/preferences';
import styled from 'styled-components';
import { ClientModal } from '../ClientModal';
import { Client, Remaining } from 'api';
import { useHistory, Link } from 'react-router-dom';
import {
  SelectCondVenta,
  SelectPais,
  SelectTransporte,
  SelectModVenta,
  SelectTipoVenta,
} from './Selects';
import { departamentos } from 'constants/departamentos';
import { Key } from 'rc-select/lib/interface/generator';
import * as api from 'api';
import NumericFormat from 'react-number-format';

const confirm = Modal.confirm;

const { TextArea } = Input;

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
};

const SelectIva = ({ disabled = false }: { disabled?: boolean }) => (
  <Form.Item name="tipoIva" noStyle>
    <Select disabled={disabled}>
      <Option value={1.22}>22%</Option>
      <Option value={1.1}>10%</Option>
      <Option value={1.0}>0%</Option>
    </Select>
  </Form.Item>
);

const SelectCurrency = ({ disabled = false, fecha }: ({ disabled?: boolean, fecha: moment.Moment })) => {
  // Redux
  const dispatch = useDispatch()
  /*let {
    interbancario,
  } = useSelector(({ app }: RootState) => ({
    interbancario: app.interbancario
  }))*/

  return <Form.Item
    name="moneda"
    noStyle
    rules={[
      () => ({
        async validator(_, value) {
          if (value === 'UYU') {
            return Promise.resolve();
          }

          if (value === 'USD') {
            const interbancario = await api.getInterbancario(fecha.format('YYYY-MM-DD'));
            dispatch(setInterbancario(interbancario))
            if (interbancario !== 0) {
              return Promise.resolve();
            }
          }

          return Promise.reject(new Error('Valor del dólar interbancario no ha podido descargarse correctamente, por favor pruebe más tarde.'));
        },
      })
    ]}>
    <Select disabled={disabled}>
      <Option value="UYU">Pesos</Option>
      <Option value="USD">Dólares</Option>
    </Select>
  </Form.Item>
};

const SelectRedondeo = ({ disabled = false }: { disabled?: boolean }) => (
  <Form.Item name="redondeoAplica" noStyle>
    <Select disabled={disabled}>
      <Option value={'A'}>A</Option>
      <Option value={'N/A'}>N/A</Option>
    </Select>
  </Form.Item>
);

const PrefixSelect = styled(Select)`
  width: 70x;
`;

const rutOCiSelector = (
  <Form.Item name="documentType" noStyle>
    <PrefixSelect>
      <Select.Option value="RUT">RUT</Select.Option>
      <Select.Option value="CI">CI</Select.Option>
    </PrefixSelect>
  </Form.Item>
);



export type DocTypes = 'Ticket' | 'Factura' | 'Resguardo' | 'Exportación' | 'Remito';

interface State {
  type: DocTypes;
  showAgenda: boolean;
  isNC: boolean;
  ObservacionesValue: string,//pooh
  pais?: string,
  zfTipoVenta?: string,
  moneda?: string
}

const recalculateKeys = [
  'tipoIva',
  'descuento',
  'impuestos',
  'cantidad',
  'precio',
  'moneda',
  'redondeoAplica'
];

interface Totales {
  iva: number;
  descuentoTotal: number;
  neto: number;
  subtotal: number;
  redondeo: number;
  total: number;
}

export interface Line {
  cantidad?: string;
  detalle?: string;
  precio?: string;
  total?: string;
  mnf?: boolean;
}

export const DocumentForm = () => {
  const {
    remainingRedux,
    preferences: { impuestos, moneda, tipoIva, usoNF },
    isAdmin,
    loading,
    postState,
    error
  } = useSelector(({ docs, preferences, app }: RootState) => ({
    remainingRedux: docs.remaining,
    preferences,
    isAdmin: app.isAdmin,
    postState: docs.postState,
    error: docs.error,
    loading: docs.state === 'LOADING' //|| app.interbancario === null
  }));

  const [totales, setTotales] = useState<Totales>();
  const [remaining, setRemaining] = useState<api.Remaining[]>([])
  const [fecha, setFecha] = useState<moment.Moment>(moment());
  const [state, setState] = useState<State>({
    type: 'Ticket',
    showAgenda: false,
    isNC: false,
    ObservacionesValue: "",//pooh
    pais: '',
    zfTipoVenta: '',
    moneda: moneda === 'Dólares' ? 'USD' : 'UYU'
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(updateRemaining());
    dispatch(updatePreferences());
  }, [dispatch]);

  /*useEffect(() => {
    handleMonedaChange(moneda)
  }, [fecha])*/

  const setAgenda = (value: boolean) =>
    setState((prev) => ({ ...prev, showAgenda: value }));

  const [bigForm] = Form.useForm();
  const [lineas] = Form.useForm();
  const [resguardo] = Form.useForm();

  const changeObservacionesPrefix = (newType: DocTypes) => {
    let result = state.ObservacionesValue;
    if (newType == 'Ticket') {
      result = result.replace("Factura", "Ticket");
      result = result.replace("Exportación", "Ticket");
    }
    if (newType == "Factura") {
      result = result.replace("Ticket", "Factura");
      result = result.replace("Exportación", "Factura");
    }
    if (newType == "Exportación") {
      result = result.replace("Factura", "Exportación");
      result = result.replace("Ticket", "Exportación");
    }
    setState((prev) => ({ ...prev, ObservacionesValue: result }));
    return result;
  }

  const clearObservaciones = (event: any) => {
    if (event === "Motivo") setState((prev) => ({ ...prev, ObservacionesValue: "" }));
  }

  const resguardoOCiSelector = (
    <Form.Item name="referenciaType" noStyle>
      <PrefixSelect onChange={clearObservaciones}>
        <Select.Option value="Referencia">Referencia</Select.Option>
        <Select.Option value="Motivo">Motivo</Select.Option>
      </PrefixSelect>
    </Form.Item>
  );

  const handleUpdateInterbancario = (fechaParam: moment.Moment) => {
    dispatch(getInterbancario(fechaParam.format('YYYY-MM-DD')))
  }

  const handleFormChange: FormProviderProps['onFormChange'] = (
    name: string,
    { changedFields, forms }
  ) => {
    if (name === 'bigForm') {
      const tipoField = changedFields.find((f) =>
        Array.isArray(f.name) ? f.name.includes('tipo') : false
      );

      const referenciaValue = changedFields.find((f) =>
        Array.isArray(f.name) ? f.name.includes('referencia') : false
      );

      const paisField = changedFields.find((f) =>
        Array.isArray(f.name) ? f.name.includes('pais') : false
      );

      const zfTipoVentaField = changedFields.find((f) =>
        Array.isArray(f.name) ? f.name.includes('Zona_Franca_Tipo_Venta') : false
      );

      if (tipoField && tipoField.value != null) {
        const tipo = remaining[tipoField.value];
        let newType: DocTypes = 'Ticket';

        if (tipo.tipo.match(/ticket/gi)) {
          newType = 'Ticket' as const;
        } else if (tipo.tipo.match(/factura/gi)) {
          newType = 'Factura';
        } else if (tipo.tipo.match(/resguardo/gi)) {
          newType = 'Resguardo';
        } else if (tipo.tipo.match(/remito/gi)) {
          newType = 'Remito';
          //forms.bigForm.setFieldsValue({ tipoIva: 1.0, moneda: 'USD' });
          forms.bigForm.setFieldsValue({ tipoIva: 1.0 })
          // tipo.tipoIva = '0%'
        } else if (tipo.tipo.match(/export/gi)) {
          newType = 'Exportación';
          forms.bigForm.setFieldsValue({ tipoIva: 1.0, moneda: 'USD' });
          //handleUpdateInterbancario(fecha)
        }
        /*if (tipo.tipo.match(/nc/gi)) {
          setState((prev) => ({ ...prev, type: newType, isNC: true}));
        } else {
          forms.bigForm.setFieldsValue({ observaciones: '' });
          setState((prev) => ({ ...prev, type: newType, isNC: false, ObservacionesValue: "" }));
        }*/

        forms.bigForm.setFieldsValue({ observaciones: '' });
        setState((prev) => ({
          ...prev,
          type: newType,
          isNC: /nc|nd/gi.test(tipo.tipo), ObservacionesValue: "",
          pais: newType !== 'Exportación' ? '' : prev.pais
        }));

        forms.bigForm.setFieldsValue({
          serie: tipo.serie,
          numero: tipo.desde + tipo.utilizados,
          documentType: newType === 'Ticket' ? 'CI' : 'RUT'
        });

        if (newType !== 'Exportación') {
          forms.bigForm.setFieldsValue({
            pais: '',
            Zona_Franca_Tipo_Venta: '',
            Zona_Franca_Servicios: ''
          })
          setState(prev => ({ ...prev, zfTipoVenta: '' }))
        }
      }

      if (paisField && paisField.value !== null) {
        setState((prev) => ({ ...prev, pais: paisField.value }));
      }

      const tipoComprobante = remaining[forms.bigForm.getFieldsValue()?.tipo];
      if (referenciaValue && referenciaValue.value != null && /nc/gi.test(tipoComprobante.tipo)) {
        const result = changeObservacionesPrefix(state.type);
        forms.bigForm.setFieldsValue({ observaciones: result });
      }

      const fechaField = changedFields.find((f) =>
        Array.isArray(f.name) ? f.name.includes('fecha') : false
      );
      if (fechaField && !forms.bigForm.isFieldValidating('fecha')) {
        //console.log(state.type)
        setFecha(fechaField.value);
        /*if (state.type === 'Exportación') {
          handleUpdateInterbancario(fechaField.value)
        }*/
      }

      if (zfTipoVentaField && zfTipoVentaField.value !== null) {
        setState((prev) => ({ ...prev, zfTipoVenta: zfTipoVentaField.value }))
        if (zfTipoVentaField.value === 'Productos') {
          forms.bigForm.setFieldsValue({ Zona_Franca_Servicios: '' });
        }
      }

      const monedaField = changedFields.find((f) =>
        Array.isArray(f.name) ? f.name.includes('moneda') : false
      );
      if (monedaField) {
        if (monedaField.value === 'USD') {
          forms.bigForm.setFieldsValue({ redondeoAplica: 'N/A' });
          // handleMonedaChange(monedaField.value === 'USD' ? 'Dólares' : 'Pesos')
        }
        setState((prev) => ({ ...prev, moneda: monedaField.value }))
      }

    }

    if (changedFields.some((field) => {
      if (Array.isArray(field.name)) {
        return field.name.some((n) => recalculateKeys.includes(String(n)));
      } else {
        return recalculateKeys.includes(String(field.name));
      }
    })
    ) {
      const lineas = forms.lines.getFieldsValue().lineas as Line[];

      const lineasWithTotals = lineas.map((line) => ({
        ...line,
        precio:
          line.precio
            ? showToFour(line.precio)
            : line.precio,
        cantidad:
          line.cantidad
            ? showToThree(line.cantidad)
            : line.cantidad,
        total: formatImporte(
          round((Number(line.cantidad ? line.cantidad.toString().replace(',', '.') : '0') || 1)
            * (Number(line.precio ? line.precio.toString().replace(',', '.') : '0') || 0))
        ),
      }));

      forms.lines.setFieldsValue({ lineas: lineasWithTotals });

      const {
        descuento = 0,
        tipoIva,
        impuestos,
      } = forms.bigForm.getFieldsValue();

      const ivaIncluido = impuestos === 'IVA INC';

      const totals = lineas.map((line) => {
        const cantidad = (Number(line.cantidad ? line.cantidad.toString().replace(',', '.') : '0') || 1);
        const unitario = (Number(line.precio ? line.precio.toString().replace(',', '.') : '0') || 0);
        return round(cantidad * unitario);
      });

      const linesTotal = totals.reduce((acc, l) => acc + l, 0);

      const subtotal = ivaIncluido ? linesTotal / tipoIva : linesTotal;

      const neto = subtotal * (1 - descuento / 100);

      const descuentoTotal = subtotal - neto;
      let total = neto * tipoIva;
      const iva = total - neto;
      const monedaC = forms.bigForm.getFieldsValue()?.moneda;
      const redondeoAplica = forms.bigForm.getFieldsValue()?.redondeoAplica;
      let redondeo = 0;
      if (monedaC === 'UYU' && redondeoAplica === 'A') {
        const prevTotal = total
        total = Math.round(total);
        redondeo = total - prevTotal;
      }

      setTotales({
        iva,
        descuentoTotal,
        neto,
        subtotal,
        redondeo,
        total,
      });

      forms.bigForm.setFieldsValue({
        ivaFormatted: formatImporte(iva),
        descuentoTotalFormatted: formatImporte(descuentoTotal),
        netoFormatted: formatImporte(neto),
        subtotalFormatted: formatImporte(neto),
        totalFormatted: formatImporte(total),
      });
    }
  };

  const handleFormFinish: FormProviderProps['onFormFinish'] = async (
    name: string,
    { values, forms }
  ) => {
    if (name === 'bigForm') {
      if (state.type !== 'Resguardo') {
        const { lineas } = await forms.lines.validateFields();
        const tipo = remaining[values.tipo];

        if (values.montoNoFacturable) {
          values.montoNoFacturable = Number(values.montoNoFacturable.replace('.', '').replace(',', '.'))
        }

        let payload = {
          ...values,
          ...totales,
          cfe: tipo.CFE,
          lineas,
        };

        const today = moment();
        if (values.fecha.isAfter(today)) {
          confirm({
            title: '¿Seguro que desea emitir una factura con fecha futura?',
            okText: 'Continuar',
            cancelText: 'Cancelar',
            onOk() {
              if (state.type === 'Ticket' && values.documentType === 'RUT') {
                confirm({
                  title: 'Está por emitir un eTicket con RUT ¿Seguro que desea continuar?',
                  okText: 'Continuar',
                  cancelText: 'Cancelar',
                  onOk() {
                    dispatch(postDocumentThunk({ doc: payload, lineas, type: state.type }));
                  },
                  onCancel() { },
                });
              } else {
                dispatch(postDocumentThunk({ doc: payload, lineas, type: state.type }));
              }
            },
            onCancel() { },
          });
        } else {
          dispatch(postDocumentThunk({ doc: payload, lineas, type: state.type }));
        }
      } else {
        const resguardoFields = await forms.resguardo.validateFields();

        const imponible = resguardoFields.montoImponible || 0;
        const tasa = resguardoFields.tasa;
        const montoRetenido = imponible * (tasa / 100);

        const payload = {
          ...values,
          ...resguardoFields,
          total: montoRetenido,
          cfe: 182,
        };

        dispatch(
          postDocumentThunk({
            doc: payload,
            type: state.type,
            resguardo: resguardoFields,
          })
        );
      }
    }
  };

  const dateChecks = (date: moment.Moment): boolean => {
    const today = moment();
    if (date.isBefore(today)) {
      return date.isBefore(today.subtract(isAdmin ? 1220 : 40, 'days'));
    }
    //return date.isAfter(today.add(1, 'day').startOf('day'));
    return date.isAfter(today.endOf('month'));
  };

  const handleClient = (client: Client) => {
    const facturaIndex = remaining.findIndex((r) => r.tipo === 'e-Factura');
    const ticketIndex = remaining.findIndex((r) => r.tipo === 'e-Ticket');

    const newIndex = client.rut ? facturaIndex : ticketIndex;
    const newValidIndex = newIndex === -1 ? 0 : newIndex;

    const tipo = remaining[newValidIndex];
    let newType: DocTypes = 'Ticket';

    if (tipo.tipo.match(/ticket/gi)) {
      newType = 'Ticket' as const;
    } else if (tipo.tipo.match(/factura/gi)) {
      newType = 'Factura';
    } else if (tipo.tipo.match(/exportación/gi)) {
      newType = 'Exportación';
    }
    if (state.type !== 'Resguardo') {
      setState((prev) => ({
        ...prev,
        type: newType,
        isNC: !!tipo.tipo.match(/nc/gi),
      }));
    }

    state.type === 'Resguardo'
      ? bigForm.setFieldsValue(client)
      : bigForm.setFieldsValue({
        ...client,
        tipo: newValidIndex,
        serie: tipo.serie,
        numero: tipo.desde + tipo.utilizados,
      });
    setAgenda(false);
  };

  const resetState = () => {
    console.log(resetState)
    bigForm.resetFields();
    lineas.resetFields();
    resguardo.resetFields();
    dispatch(setPostState({ state: 'READY' }));
    setState({
      type: 'Ticket',
      showAgenda: false,
      isNC: false,
      ObservacionesValue: "",
      pais: '',
      zfTipoVenta: '',
      moneda: moneda === 'Dólares' ? 'USD' : 'UYU'
    });
    setTotales(undefined);
  };



  const showObservacionesEvent = (event: any) => {
    if (state.isNC) {
      let resultStr = event.target.value;

      if (state.type == 'Ticket') {
        resultStr = "Anulación de eTicket n° " + resultStr;
      }
      if (state.type == "Factura") {
        resultStr = "Anulación de eFactura n° " + resultStr;
      }
      if (state.type == "Exportación") {
        resultStr = "Anulación de eFactura de Exportación n° " + resultStr;
      }

      setState((prev) => ({ ...prev, ObservacionesValue: resultStr }));
    }
  }

  const handleKeydownEvent = (event: any) => {
    if (event.keyCode === 190 || event.keyCode === 110 || event.keyCode === 188) {
      let dot_count = event.target.value.toString().match(/\./g);
      let comma_count = event.target.value.toString().match(/\,/g);
      dot_count = dot_count ? dot_count.length : 0;
      comma_count = comma_count ? comma_count.length : 0;
      if (dot_count + comma_count > 0) event.preventDefault();
    }
  }

  const handleKeyBlockdownEvent = (event: any) => {
    if (event.key == '&') {
      event.preventDefault();
    }
  }

  const handleObservacionesChange = (event: any) => {

    //setValue('observaciones', 'value='+event.target.value);
    setState((prev) => ({ ...prev, ObservacionesValue: event.target.value }));
  }

  const arrayUnique = (arr: Array<any>, key: string) => {
    return arr.filter((v, i, a) => a.findIndex(v2 => v[key] === v2[key]) === i)
  }

  useEffect(() => {
    if (remaining.length) {
      console.log(remaining[0])
      const tipo = remaining[0];
      let newType: DocTypes = 'Ticket';

      if (tipo.tipo.match(/ticket/gi)) {
        newType = 'Ticket' as const;
      } else if (tipo.tipo.match(/factura/gi)) {
        newType = 'Factura';
      } else if (tipo.tipo.match(/resguardo/gi)) {
        newType = 'Resguardo';
      } else if (tipo.tipo.match(/exportación/gi)) {
        newType = 'Exportación'
      }

      if (tipo.tipo.match(/nc/gi)) {
        setState((prev) => ({ ...prev, type: newType, isNC: true }));
      } else {
        setState((prev) => ({ ...prev, type: newType, isNC: false }));
      }
    }
  }, [remaining]);

  useEffect(() => {
    setRemaining(arrayUnique(remainingRedux, 'CFE'))
  }, [remainingRedux])

  if (postState === 'ERROR' || postState === 'SUCCESS') {
    const isSuccess = postState === 'SUCCESS';
    return (
      <Section>
        <Result
          status={isSuccess ? 'success' : 'error'}
          title={
            isSuccess
              ? 'Su documento se creó con éxito'
              : 'A ocurrido un error al crear el documento'
          }
          subTitle={isSuccess ? undefined : error?.toString()}
          extra={[
            <Link to="/" onClick={resetState}>
              <Button type="primary">Ir a mis Documentos</Button>
            </Link>,
            <Button key="buy" onClick={resetState}>
              Ingresar otro Documento
            </Button>,
          ]}
        />
      </Section>
    );
  }

  return (
    <>
      <ClientModal
        type={state.type === 'Resguardo' ? 'Provider' : 'Client'}
        onCancel={() => setAgenda(false)}
        onOk={handleClient}
        visible={state.showAgenda}
      />
      <Spin
        size='large'
        spinning={loading || postState === 'LOADING'}
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: "translate(-50%, -50%)"
        }}>
        <Form.Provider
          onFormChange={handleFormChange}
          onFormFinish={handleFormFinish}
        >
          {remaining.length ? (
            <Form
              size="large"
              form={bigForm}
              name="bigForm"
              {...formItemLayout}
              labelAlign="left"
              initialValues={{
                moneda: moneda === 'Dólares' ? 'USD' : 'UYU',
                impuestos,
                TipoRemito: 'Interno',
                formaPago: 'contado',
                tipoIva: tipoIva === '0%' ? 1.0 : tipoIva === '22%' ? 1.22 : 1.1,
                tipo: 0,
                serie: remaining[0].serie,
                numero: remaining[0].desde + remaining[0].utilizados,
                fecha: moment(new Date()),
                descuento: 0,
                observaciones: '',
                ordenCompra: '',
                iva: 0,
                descuentoTotal: 0,
                neto: 0,
                subtotal: 0,
                redondeo: 0,
                redondeoAplica: moneda === 'Dólares' ? 'N/A' : 'A',
                total: 0,
                documentType: 'CI',
                referenciaType: 'Referencia',
              }}
              hideRequiredMark
            >
              <Section>
                <Row justify="space-between" gutter={32}>
                  <Col xl={12} sm={24}>
                    <Form.Item
                      name="nombre"
                      label="Nombre"
                      shouldUpdate={(prev, curr) => prev.nombre !== curr.nombre}
                      rules={[
                        {
                          required: state.type === 'Ticket' ? false : true,
                          message: 'Nombre es requerido',
                        },
                      ]}
                    >
                      <Input
                        onKeyDown={handleKeyBlockdownEvent}
                        suffix={
                          <ClickableIcon
                            title="Agenda"
                            type="primary"
                            onClick={() => setAgenda(true)}
                          >
                            <BookOutlined />
                          </ClickableIcon>
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="direccion"
                      label="Dirección"
                      rules={[
                        {
                          required: state.type === 'Ticket' ? false : true,
                          message: 'Dirección es requerido',
                        },
                      ]}
                    >
                      <Input maxLength={70} />
                    </Form.Item>
                    <Form.Item
                      name="ciudad"
                      label="Ciudad"
                      rules={[
                        {
                          required: state.type === 'Ticket' ? false : true,
                          message: 'Ciudad es requerido',
                        },
                      ]}
                    >
                      <Input maxLength={30} />
                    </Form.Item>
                    <Form.Item
                      name="departamento"
                      label="Departamento"
                      rules={[
                        {
                          required: state.type === 'Ticket' ? false : true,
                          message: 'Departamento es requerido',
                        },
                      ]}
                    >
                      {
                        state.type === 'Exportación' ?
                          <Input />
                          :
                          <Select placeholder="Seleccione Departamento">
                            {departamentos.map((d) => (
                              <Select.Option key={d} value={d}>
                                {d}
                              </Select.Option>
                            ))}
                          </Select>
                      }
                    </Form.Item>
                    {state.type === 'Exportación' && <SelectPais />}
                    {state.type === 'Exportación' && (
                      <Form.Item
                        name="NIFE"
                        label="NIFE"
                        rules={[
                          {
                            max: 20,
                            message: 'NIFE no puede tener más de 20 caracteres',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}

                    {state.type === 'Factura' && (
                      <Form.Item
                        name="rut"
                        label="RUT"
                        rules={[
                          { required: true, message: 'RUT es requerido' },
                          {
                            pattern: rutRegex,
                            message: 'Ingrese un RUT Válido',
                            len: 12,
                          },
                        ]}
                      >
                        <Input maxLength={12} />
                      </Form.Item>
                    )}
                    {(state.type === 'Resguardo' || state.type === 'Ticket' || state.type === 'Remito') && (
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, curr) =>
                          prev.documentType !== curr.documentType
                        }
                      >
                        {({ getFieldValue }) => {
                          return getFieldValue('documentType') === 'RUT' ? (
                            <Form.Item
                              name="rut"
                              label="RUT"
                              rules={[
                                { required: true, message: 'RUT es requerido' },
                                {
                                  pattern: rutRegex,
                                  message: 'Ingrese un RUT Válido',
                                  len: 12,
                                },
                              ]}
                            >
                              <Input addonBefore={rutOCiSelector} maxLength={12} />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              name="cedula"
                              label="Cédula"
                              rules={[
                                {
                                  pattern: ciRegex,
                                  message:
                                    'Ingrese una Cédula Válida ej: 4.283.298-4',
                                },
                              ]}
                            >
                              <Input
                                addonBefore={rutOCiSelector}
                                placeholder="ej: 4.283.298-4"
                                maxLength={12}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    )}
                  </Col>
                  <Col xl={12} sm={24}>
                    <Form.Item
                      name="tipo"
                      label="Tipo"
                      rules={[{ required: true, message: 'Tipo es requerido' }]}
                    >
                      <Select>
                        {remaining.map((r: Remaining, i: Key) => (
                          <Option key={r.CFE} value={i}>
                            {r.tipo}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {(state.isNC || state.type === 'Resguardo') && (
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, curr) =>
                          prev.referenciaType !== curr.referenciaType
                        }
                      >
                        {({ getFieldValue }) => {
                          return getFieldValue('referenciaType') ===
                            'Referencia' ? (
                            <Form.Item
                              name="referencia"
                              label="Referencia"
                              rules={[
                                {
                                  required: true,
                                  message: 'Referencia es requerido',
                                },
                                {
                                  pattern: onlyNumbersRegex,
                                  validator: (rule, value) =>
                                    Number(value) < 9999999
                                      ? Promise.resolve()
                                      : Promise.reject(
                                        'Numero tiene que ser menor que 9.999.999'
                                      ),
                                  message: 'Debe incluir solo Números',
                                },
                              ]}
                            >
                              <Input
                                addonBefore={resguardoOCiSelector}
                                type="number"
                                onChange={showObservacionesEvent}
                                maxLength={7}
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              name="motivo"
                              label="Motivo"
                              rules={[
                                {
                                  required: true,
                                  message: 'Motivo es requerido',
                                },
                              ]}
                            >
                              <Input
                                addonBefore={resguardoOCiSelector}
                                type="text"
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    )}
                    <Form.Item
                      name="fecha"
                      label="Fecha"
                      rules={[{ required: true, message: 'Fecha es requerido' }]}
                    >
                      <DatePicker
                        locale={datepickerLocale}
                        disabledDate={dateChecks}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                    <Form.Item label="Numero">
                      <Input.Group compact>
                        <Form.Item noStyle name="serie">
                          <Input disabled style={{ width: '20%' }} />
                        </Form.Item>
                        <Form.Item noStyle name="numero">
                          <InputRight disabled style={{ width: '80%' }} />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                    {(state.type !== 'Resguardo' && state.type !== 'Remito') && (
                      <>
                        <Form.Item name="formaPago" label="Forma de Pago">
                          <Select>
                            <Option value="contado">Contado</Option>
                            <Option value="crédito">Crédito</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="impuestos" label="Ingresar Precios">
                          <Select>
                            <Option value="+ IVA">+ IVA</Option>
                            <Option value="IVA INC">IVA INC</Option>
                          </Select>
                        </Form.Item>
                      </>
                    )}
                    {state.type === 'Remito' && (
                      <>
                        <Form.Item name="TipoRemito" label="Tipo">
                          <Select>
                            <Option value="interno">Interno</Option>
                            <Option value="venta">Venta</Option>
                          </Select>
                        </Form.Item>
                      </>
                    )}

                    {state.type === 'Exportación' && state.pais === 'UY' && <Form.Item
                      name="Zona_Franca_Número"
                      label="Reg zona franca"
                      rules={[{ required: true, message: 'Número de registro zona franca es requerido' }]}
                    >
                      <input style={{ display: 'none' }} autoComplete="cc-csc" />
                      <Input
                        autoComplete='off'
                      />
                    </Form.Item>}
                  </Col>
                </Row>
              </Section>
              <Section>
                {state.type !== 'Resguardo' ? (
                  <FormLines>
                    <Form
                      size="large"
                      layout="vertical"
                      name="lines"
                      form={lineas}
                      initialValues={{ lineas: [{}] }}
                      hideRequiredMark
                    >
                      <Form.List name="lineas">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              {fields.map((field, index) => (
                                <Row key={field.key}>
                                  <Col xs={24} md={4}>
                                    <SmallFormItem
                                      className="first"
                                      label={index === 0 ? 'Cantidad' : undefined}
                                      name={[field.name, 'cantidad']}
                                    >
                                      <InputRight
                                        placeholder="Cantidad"
                                        type="text"
                                        onKeyDown={handleKeydownEvent}
                                        addonBefore={
                                          <ClickableIcon
                                            title="Eliminar"
                                            type="danger"
                                            onClick={() =>
                                              index > 0 && remove(index)
                                            }
                                          >
                                            <DeleteOutlined />
                                          </ClickableIcon>
                                        }
                                      />
                                    </SmallFormItem>
                                  </Col>
                                  <Col flex={1} xs={24} md={10}>
                                    <SmallFormItem
                                      label={index === 0 ? 'Detalle' : undefined}
                                      name={[field.name, 'detalle']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Detalle es requerido',
                                        },
                                      ]}
                                    >
                                      <Input.TextArea
                                        placeholder="Detalle"
                                        onKeyDown={handleKeyBlockdownEvent}
                                        rows={1}
                                        style={{
                                          paddingTop: 8,
                                          paddingBottom: 8
                                        }}
                                      />
                                    </SmallFormItem>
                                  </Col>
                                  <Col xs={24} span={6} md={5}>
                                    <SmallFormItem
                                      label={
                                        index === 0
                                          ? 'Precio Unitario'
                                          : undefined
                                      }
                                      name={[field.name, 'precio']}
                                      rules={[
                                        {
                                          required: state.type !== 'Remito' ? true : false,
                                          message: 'Precio unitario es requerido',
                                        },
                                      ]}
                                    >
                                      {state.type === 'Remito' ? (
                                        <InputRight
                                          placeholder="Precio Unitario"
                                          type="text"
                                          onKeyDown={handleKeydownEvent}
                                          disabled
                                        />
                                      )
                                        :
                                        (
                                          <InputRight
                                            placeholder="Precio Unitario"
                                            type="text"
                                            onKeyDown={handleKeydownEvent}
                                          />
                                        )}
                                    </SmallFormItem>
                                  </Col>
                                  <Col xs={24} md={5}>
                                    <SmallFormItem
                                      className="last"
                                      label={index === 0 ? 'Total' : undefined}
                                      name={[field.name, 'total']}
                                    >
                                      <InputRight disabled placeholder="Total" />
                                    </SmallFormItem>
                                  </Col>
                                </Row>
                              ))}
                              <Form.Item>
                                <Button type="default" onClick={add} disabled={fields.length >= 20} block>
                                  <PlusOutlined />
                                  Agregar Línea
                                </Button>
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.List>
                    </Form>
                  </FormLines>
                ) : (
                  <Form
                    size="large"
                    layout="horizontal"
                    name="resguardo"
                    form={resguardo}
                    labelAlign="left"
                    {...formItemLayout}
                    initialValues={{
                      moneda: moneda === 'Dólares' ? 'USD' : 'UYU',
                      tipoIva: tipoIva === '0%' ? 1.0 : tipoIva === '22%' ? 1.22 : 1.11,
                    }}
                    hideRequiredMark
                  >
                    <Typography>
                      <Typography.Title level={4}>Retención</Typography.Title>
                    </Typography>
                    <Row justify="space-between" gutter={32}>
                      <Col xl={12} sm={24}>
                        <Form.Item
                          name="codRetencion"
                          label="Código de retención"
                          rules={[
                            {
                              required: true,
                              message: 'Código de retención es requerido',
                            },
                            {
                              pattern: codRetencionRegex,
                              message:
                                'Código de retención es un número de 7 dígitos',
                            },
                          ]}
                        >
                          <InputRight maxLength={7} />
                        </Form.Item>
                        <Form.Item
                          name="tasa"
                          label="Tasa"
                          rules={[
                            {
                              required: true,
                              message: 'Tasa es requerido',
                            },
                            {
                              validator: (rule, value) => {
                                const number = Number(value);
                                if (number < 100 && number > 0) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  'Tasa tiene que ser mayor a 0 y menor que 100'
                                );
                              },
                            },
                          ]}
                        >
                          <InputRight type="number" addonBefore="%" />
                        </Form.Item>
                      </Col>
                      <Col xl={12} sm={24}>
                        <Form.Item
                          name="montoImponible"
                          label="Monto Imponible"
                          rules={[
                            {
                              required: true,
                              message: 'Monto Imponible es requerido',
                            },
                            {
                              validator: (rule, value) => {
                                const number = Number(value);
                                if (number > 0) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  'Monto imponible tiene que ser mayor a 0'
                                );
                              },
                            },
                          ]}
                        >
                          <InputRight
                            type="number"
                            addonBefore={<SelectCurrency fecha={fecha} />}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Monto Retenido"
                          shouldUpdate={(prev, curr) =>
                            prev.montoImponible !== curr.montoImponible ||
                            prev.tasa !== curr.tasa
                          }
                        >
                          {({ getFieldValue }) => {
                            const imponible =
                              getFieldValue('montoImponible') || 0;
                            const tasa = getFieldValue('tasa') || 0;
                            const montoRetenido = imponible * (tasa / 100);
                            return (
                              <InputRight
                                value={formatImporte(montoRetenido)}
                                disabled
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Section>
              <Section>
                <Row justify="space-between">
                  <Col xl={12} sm={24}>
                    {/* <TextArea name="observaciones"  value = {state.ObservacionesValue} /> */}

                    <Form.Item name="observaciones" label="Observaciones">
                      <TextArea
                        // value= {state.ObservacionesValue}
                        autoSize={{ minRows: 4 }}
                        onKeyDown={handleKeyBlockdownEvent}
                      // onChange = {handleObservacionesChange}
                      />
                    </Form.Item>
                    {state.type !== 'Resguardo' && state.type !== 'Exportación' && (
                      <Form.Item name="ordenCompra" label="Orden de compra">
                        <Input maxLength={50} />
                      </Form.Item>
                    )}

                    {state.type === 'Exportación' && (
                      <div>
                        <SelectCondVenta />
                        <SelectModVenta />
                        <SelectTransporte />
                        {state.pais === 'UY' &&
                          <>
                            <SelectTipoVenta />

                            {state.zfTipoVenta === 'Servicios' && <Form.Item
                              name="Zona_Franca_Servicios"
                              label="Art. 34 dec Nº220/998"
                              rules={[{ required: true, message: 'Numeral del artículo 34 del Decreto Nº 220/998 es requerido' }]}
                            >
                              <Input
                                autoComplete='off'
                              />
                            </Form.Item>}
                          </>}
                      </div>
                    )}
                    {usoNF &&
                      <Form.Item
                        name="montoNoFacturable"
                        label="Monto no facturable"
                      >
                        {/*<InputRight
                        type='number'
                        onKeyDown={handleKeydownEvent}
                        //onChange={e => console.log(e.target.value)}
                        //value={montoNoFacturable}
                  />*/}
                        <NumericFormat
                          thousandSeparator='.'
                          decimalSeparator=','
                          allowNegative={false}
                          customInput={InputRight}
                        />

                      </Form.Item>}
                  </Col>
                  <Col xl={8} sm={24}>
                    {state.type !== 'Resguardo' ? (
                      <>
                        <Form.Item label="Subtotal" className='fieldMarginBottom'>
                          <InputRight
                            disabled
                            value={formatImporte(totales?.subtotal)}
                          />
                        </Form.Item>
                        <Form.Item label="Descuento" className='fieldMarginBottom'>
                          <Input.Group compact>
                            <Form.Item name="descuento" noStyle>
                              <InputRight prefix="%" style={{ width: '40%' }} />
                            </Form.Item>
                            <Form.Item noStyle>
                              <InputRight
                                style={{ width: '60%' }}
                                disabled
                                value={formatImporte(totales?.descuentoTotal)}
                              />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                        <Form.Item label="Neto" className='fieldMarginBottom'>
                          <InputRight
                            disabled
                            value={formatImporte(totales?.neto)}
                          />
                        </Form.Item>
                        <Form.Item label="IVA" name="ivaFormatted" className='fieldMarginBottom'>
                          <InputRight
                            addonBefore={
                              <SelectIva
                                disabled={state.type === 'Exportación' || state.type === 'Remito'}
                              />
                            }
                            disabled
                          />
                        </Form.Item>
                        <Form.Item label="Redondeo" className='fieldMarginBottom'>
                          <InputRight
                            disabled
                            addonBefore={
                              <SelectRedondeo
                                disabled={state.moneda === 'USD'}
                              />
                            }
                            value={formatImporte(totales?.redondeo)}
                          />
                        </Form.Item>
                        <Form.Item label="Total">
                          <InputRight
                            disabled
                            addonBefore={
                              <SelectCurrency
                                fecha={fecha}
                                disabled={state.type === 'Exportación' || state.type === 'Remito'}
                              />
                            }
                            value={formatImporte(totales?.total)}
                          />
                        </Form.Item>
                      </>
                    ) : null}
                  </Col>
                </Row>
                <Row justify="end">
                  <Space size="large">
                    <Button onClick={history.goBack}>Cancelar</Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={lineas.submit}
                    >
                      Aceptar
                    </Button>
                  </Space>
                </Row>
              </Section>
            </Form>
          ) : (
            <Result
              status="warning"
              title="No se encontró numeración disponible para emitir nuevos documentos."
              subTitle="Comuníquese con Infra Sistemas"
            />
          )}
        </Form.Provider>
      </Spin>
    </>
  );
};

interface ClickableIconProps {
  children: React.ReactNode;
  onClick: () => void;
  type: 'primary' | 'danger';
  title: string;
}
const ClickableIcon = ({
  children,
  type,
  onClick,
  title,
}: ClickableIconProps) => {
  return (
    <Tooltip title={title}>
      <Container type={type} onClick={onClick}>
        {children}
      </Container>
    </Tooltip>
  );
};
const Container = styled.div<{ type: 'primary' | 'danger' }>`
  cursor: pointer;
  :hover {
    color: ${({ type }) => (type === 'primary' ? '#0851a1' : '#f5222d')};
  }
`;
interface WidthProps {
  width: string | number;
}

const InputRight = styled(Input)`
  text-align: right !important;
`;

const SmallFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

export const Section = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: white;

  .ant-input-disabled {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  @media (max-width: 720px) {
    .ant-form-item {
      margin-bottom: 4px;
    }

    label {
      height: unset !important;
    }
  }
`;

export const FormLines = styled.div`
  .ant-form {
    border-radius: 4px;
    input {
      border-radius: 0;
    }
  }
  .ant-col {
    &:first-child {
      .ant-form-item-label {
        border-top-left-radius: 4px;
      }
    }
    &:last-child {
      .ant-form-item-label {
        border-top-right-radius: 4px;
      }
      .ant-form-item-control {
        input {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }
    .ant-form-item-label {
      background-color: rgba(8, 81, 161, 0.9) !important;
      text-align: center;
      padding-bottom: 0;
      margin-bottom: 4px;
      label {
        color: white;
        font-weight: bold;
      }
    }
  }
`;

{/*<InputNumber
                      style={{
                        width: '100%',
                      }}
                      //decimalSeparator=','
                      //precision={2}
                      formatter={value => {
                        console.log(value)
                        return Intl.NumberFormat('es-UY', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      }
                    ).format(Number(value))}}
                      parser={value => {
                        const v = value!.replace(/(\.*)/g, '').replace(',', '.')
                        console.log(value, v)
                        return v}
                      }


                    //onKeyDown={handleKeydownEvent}
                    />*/}