import { DocTypes } from './../components/DocumentForm/index';
import { Line } from '../components/DocumentForm';
/* eslint-disable @typescript-eslint/camelcase */
import { formatDecimal } from 'utils';
import { countries } from 'constants/countries';

export interface FormattedDocument {
  RutEmisor: string;
  Nombre: string;
  Direccion: string;
  Ciudad: string;
  Departamento: string;
  CI: string;
  Rut: number;
  Tipo: number;
  Serie: string;
  Numero: number;
  Fecha: string; // dd/MM/yyyy
  FormaPago: string;
  Observaciones?: string;
  TipoIva: Iva | '';
  Redondeo: string;
  Total: string;
  Moneda: string;
  Orden_Compra?: string;
  Interbancario: string;
  //   Lineas: any[];
  CantLineas: number;
  Referencia: string;
  Motivo: string;
  Subtotal: string; // Formatted Decimals Neto
  Iva: string;
}

export interface FormattedLinea {
  RutEmisor: string;
  Id: number;
  Linea: number; //Index
  Detalle: string;
  Cantidad: string; //Formatted Decimals
  Precio: string; // Formatted Decimals
  Importe: string; //Formatted Decimals
  IVA: Iva | '';
}

type Iva = '22%' | '10%' | '0%' | '0% E' | 'eBoleta' | 'NF';

const transformIva = (iva: number, type: string): Iva | '' => {
  if (type === 'Remito') return 'eBoleta';
  switch (iva) {
    case 1.0:
      return type === 'Exportación' ? '0% E' : '0%';
    case 1.1:
      return '10%';
    case 1.22:
      return '22%';
    default:
      return '';
  }
};

export const formatLineas = (
  lineas: Line[],
  formattedDocument: FormattedDocument,
  id: number,
  doc: any,
  type: string
): FormattedLinea[] =>
  lineas.map((line, index) => {
    const number_cantidad = (Number(line.cantidad ? line.cantidad.toString().replace(',', '.') : '0'));
    const number_precio = (Number(line.precio ? line.precio.toString().replace(',', '.') : '0'));
    const cantidad = number_cantidad || 1;
    const { impuestos, tipoIva } = doc;
    const ivaInc = impuestos === 'IVA INC';

    const decretoText = 'Decreto Nº 220/998'

    const detalle_adicional = type === 'Exportación' && doc.pais === 'UY' ? doc.Zona_Franca_Tipo_Venta === 'Productos' ?
      `Artículo 29 ${decretoText}` : `Numeral ${doc.Zona_Franca_Servicios} del artículo 34 del ${decretoText}`
      : ''

    const precio = (line.mnf || !ivaInc) ? number_precio : number_precio / tipoIva;
    return {
      RutEmisor: formattedDocument.RutEmisor,
      Cantidad: formatDecimal(cantidad, 4),
      Detalle: String(line.detalle),
      Id: id,
      Linea: index,
      Precio: formatDecimal(precio, 4),
      Importe: formatDecimal(cantidad * precio, 4),
      IVA: line.mnf ? 'NF' : formattedDocument.TipoIva,
      Detalle_Adicional: detalle_adicional
    };
  });

export const formatLineasResguardo = (
  resguardo: any,
  formattedDocument: FormattedDocument,
  id: number
) => [
    {
      RutEmisor: formattedDocument.RutEmisor,
      Linea: 0,
      IVA: '',
      Tipo: 182,
      Id: id,
      Detalle: resguardo.codRetencion,
      Precio: '0',
      Importe: '0',
      Cantidad: '0',
      Tasa: formatDecimal(resguardo.tasa),
      MntSujetoaRet: formatDecimal(resguardo.montoImponible),
      ValRetPerc: formatDecimal(
        resguardo.montoImponible * (resguardo.tasa / 100)
      ),
      Detalle_Adicional: ''
    },
  ];

export const formatDocument = (
  doc: any,
  rut: string,
  interbancario: number | null,
  type: DocTypes
): FormattedDocument =>
  Object.assign(EmptyFormattedDoducment, {
    RutEmisor: String(rut),
    Nombre: doc.nombre || '',
    Direccion: doc.direccion || '',
    Ciudad: doc.ciudad || '',
    Departamento: doc.departamento || '',
    CI: doc.cedula || '',
    Rut: doc.rut || '',
    Tipo: doc.cfe,
    Serie: doc.serie,
    Numero: doc.numero,
    Fecha: (doc.fecha as moment.Moment).format('DD/MM/yyyy'),
    FormaPago: doc.formaPago,
    Interbancario: interbancario ? formatDecimal(interbancario) : '0',
    CantLineas: doc.lineas?.length || 1,
    Iva: doc.iva ? formatDecimal(doc.iva) : '0',
    Referencia: doc.referencia ? doc.referencia : doc.motivo,
    Orden_Compra: doc.ordenCompra || '',
    Moneda: doc.moneda,
    Subtotal: doc.neto ? formatDecimal(doc.neto) : '0',
    TipoIva: transformIva(doc.tipoIva, type),
    Redondeo: doc.redondeo ? formatDecimal(doc.redondeo) : '0',
    Total: doc.total ? formatDecimal(doc.total) : '0',
    Monto_No_Facturable: doc.montoNoFacturable && doc.montoNoFacturable > 0 ? formatDecimal(doc.montoNoFacturable) : '0',
    Observaciones: doc.observaciones,
    Descuento: formatDecimal(doc.descuento),
    Descuento_Importe: formatDecimal(doc.descuentoTotal),
    NIFE: doc.NIFE,
    País: countries.find((c) => c.code === doc.pais)?.label.trim(),
    Código_País: doc.pais,
    FEIDDocClauVenta: doc.condicionVenta,
    FEIDDocModVenta: doc.modalidadVenta
      ? Number(doc.modalidadVenta)
      : undefined,
    FEIDDocViaTransp: doc.transporte ? Number(doc.transporte) : undefined,
    TipoRemito: doc.TipoRemito || '',
    Zona_Franca_Número: doc.Zona_Franca_Número ?? '',
    // Zona_Franca_Tipo_Venta: doc.Zona_Franca_Tipo_Venta ?? '',
    // Zona_Franca_Servicios: doc.Zona_Franca_Servicios ?? ''
  });

const EmptyFormattedDoducment = {
  RutEmisor: 0,
  Nombre: '',
  Direccion: '',
  Ciudad: '',
  Departamento: '',
  CI: '',
  Tipo: null,
  CantLineas: 1,
  Fecha: null,
  FormaPago: '',
  Interbancario: null,
  Iva: 0,
  Moneda: 'UYU',
  Numero: 0,
  Referencia: '',
  Motivo: '',
  Rut: '',
  Serie: '',
  Subtotal: 0,
  TipoIva: '22%',
  Total: 0,
  Monto_No_Facturable: 0,
  Observaciones: '',
  Orden_Compra: '',
};
