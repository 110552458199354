export const datepickerLocale = {
  lang: {
    locale: 'es_ES',
    placeholder: 'Selecccione',
    rangePlaceholder: ['Fecha Inicial', 'Fecha Final'] as [string, string],
    today: 'Hoy',
    now: 'Ahora',
    backToToday: 'Volver a hoy',
    ok: 'Ok',
    clear: 'Vaciar',
    month: 'Mes',
    year: 'Año',
    timeSelect: 'Selecciona Hora',
    dateSelect: 'Selecciona Fecha',
    monthSelect: 'Selecciona un Mes',
    yearSelect: 'Selecciona un Año',
    decadeSelect: 'Selecciona una Decada',
    yearFormat: 'YYYY',
    dateFormat: 'DD-MM-YYYY',
    dayFormat: 'DD',
    dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Mes Anterior (PageUp)',
    nextMonth: 'Mes Siguiente (PageDown)',
    previousYear: 'Año Anterior (Control + left)',
    nextYear: 'Año Siguiente (Control + right)',
    previousDecade: 'Década Anterior',
    nextDecade: 'Década Siguiente',
    previousCentury: 'Siglo Anterior',
    nextCentury: 'Siglo Siguiente',
  },
  timePickerLocale: {
    placeholder: 'Selecciona Hora',
  },
  dateFormat: 'DD-MM-YYYY',
  dateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
  weekFormat: 'wo-YYYY',
  monthFormat: 'MM-YYYY',
};
