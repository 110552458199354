import { WebService, post } from './utils';

const baseURL = 'https://infrasistemas.sytes.net:7080/WebService.asmx?op=';

const urlGetMoneda = baseURL + 'Moneda_Preferencial';
const urlPostMoneda = baseURL + 'Cambiar_Moneda_Preferencial';
const urlGetImpuestos = baseURL + 'Impuestos_Preferencial'
const urlPostImpuestos = baseURL + 'Cambiar_Impuestos_Preferencial'
const urlGetImpresion = baseURL + 'Tipo_Impresi%c3%b3n'
const urlPostImpresion = baseURL + 'Cambio_Tipo_Impresi%c3%b3n'
const urlGetIva = baseURL + 'IVA_Preferencial';
const urlPostIva = baseURL + 'Cambiar_IVA_Preferencial';
const urlGetColor = baseURL + 'Color_Preferencial';
const urlPostColor = baseURL + 'Cambio_Color_Preferencial';
const urlGetUsarMontoNF = baseURL + 'Usar_Monto_No_Facturable';
const urlPostUsarMontoNF = baseURL + 'Cambio_Monto_No_Facturable';

export const getMoneda = async (rut: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
      <Moneda_Preferencial xmlns="http://infrasistemas.sytes.net:7080/">
      <RUT>${rut}</RUT>
    </Moneda_Preferencial>
      </soap:Body>
    </soap:Envelope>`;

  const getMonedaWS: WebService<string> = {
    url: urlGetMoneda,
    name: 'Moneda_Preferencial',
    resultSelector: 'Moneda_PreferencialResult',
    sr,
    transform: (res) => res,
  };

  return post(getMonedaWS);
};

export const postMoneda = async (rut: string, moneda: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
    <Cambio_Moneda_Preferencial xmlns="http://infrasistemas.sytes.net:7080/">
    <RUT>${rut}</RUT>
    <Moneda>${moneda}</Moneda>
    </Cambio_Moneda_Preferencial>
    </soap:Body>
    </soap:Envelope>`;

  const postMonedaWS: WebService<string> = {
    url: urlPostMoneda,
    name: 'Cambio_Moneda_Preferencial',
    resultSelector: 'Cambio_Moneda_PreferencialResult',
    sr,
    transform: (res) => res,
  };

  return post(postMonedaWS);
};

export const getImpuestos = async (rut: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
    <Impuestos_Preferencial xmlns="http://infrasistemas.sytes.net:7080/">
    <RUT>${rut}</RUT>
    </Impuestos_Preferencial>
    </soap:Body>
    </soap:Envelope>`;

  const getImpuestosWS: WebService<string> = {
    url: urlGetImpuestos,
    name: 'Impuestos_Preferencial',
    resultSelector: 'Impuestos_PreferencialResult',
    sr,
    transform: (res) => res,
  };

  return post(getImpuestosWS);
};

export const postImpuestos = async (rut: string, impuestos: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
    <Cambio_Impuestos_Preferencial xmlns="http://infrasistemas.sytes.net:7080/">
    <RUT>${rut}</RUT>
    <Impuestos>${impuestos}</Impuestos>
    </Cambio_Impuestos_Preferencial>
    </soap:Body>
    </soap:Envelope>`;

  const getImpuestosWS: WebService<string> = {
    url: urlPostImpuestos,
    name: 'Cambio_Impuestos_Preferencial',
    resultSelector: 'Cambio_Impuestos_PreferencialResult',
    sr,
    transform: (res) => res,
  };

  return post(getImpuestosWS);
};

export const getImpresion = async (rut: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
  <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
      <Tipo_Impresión xmlns="http://infrasistemas.sytes.net:7080/">
        <RUT>${rut}</RUT>
      </Tipo_Impresión>
    </soap:Body>
  </soap:Envelope>`;

  const getImpresionWS: WebService<string> = {
    url: urlGetImpresion,
    name: 'Tipo_Impresión',
    resultSelector: 'Tipo_ImpresiónResult',
    sr,
    transform: (res) => res,
  };

  return post(getImpresionWS);
};

export type ImpresionType = 'A4' | 'Térmico';

export const postImpresion = async (rut: string, impresion: ImpresionType) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <Cambio_Tipo_Impresión xmlns="http://infrasistemas.sytes.net:7080/">
        <RUT>${rut}</RUT>
        <Impresión>${impresion}</Impresión>
      </Cambio_Tipo_Impresión>
    </soap12:Body>
  </soap12:Envelope>`;

  const postImpresionWS: WebService<string> = {
    url: urlPostImpresion,
    name: 'Cambio_Tipo_Impresión',
    resultSelector: 'Cambio_Tipo_ImpresiónResult',
    sr,
    transform: (res) => res,
  };

  return post(postImpresionWS);
};

export const getIva = async (rut: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
  <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
      <IVA_Preferencial xmlns="http://infrasistemas.sytes.net:7080/">
        <RUT>${rut}</RUT>
      </IVA_Preferencial>
    </soap:Body>
  </soap:Envelope>`;

  const getIvaWS: WebService<string> = {
    url: urlGetIva,
    name: 'IVA_Preferencial',
    resultSelector: 'IVA_PreferencialResult',
    sr,
    transform: (res) => res,
  };

  return post(getIvaWS);
};

export const postIva = async (rut: string, iva: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
    <Cambio_IVA_Preferencial xmlns="http://infrasistemas.sytes.net:7080/">
    <RUT>${rut}</RUT>
    <IVA>${iva}</IVA>
    </Cambio_IVA_Preferencial>
    </soap:Body>
    </soap:Envelope>`;

  const getIvaWS: WebService<string> = {
    url: urlPostIva,
    name: 'Cambio_IVA_Preferencial',
    resultSelector: 'Cambio_IVA_PreferencialResult',
    sr,
    transform: (res) => res,
  };

  return post(getIvaWS);
};

export const getColor = async (rut: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
    <Color_Preferencial xmlns="http://infrasistemas.sytes.net:7080/">
      <RUT>${rut}</RUT>
    </Color_Preferencial>
    </soap:Body>
    </soap:Envelope>`;

  const getColorWS: WebService<string> = {
    url: urlGetColor,
    name: 'Color_Preferencial',
    resultSelector: 'Color_PreferencialResult',
    sr,
    transform: (res) => res,
  };

  return post(getColorWS);
}

export const postColor = async (rut: string, color: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
    <Cambio_Color_Preferencial xmlns="http://infrasistemas.sytes.net:7080/">
      <RUT>${rut}</RUT>
      <Color>${color}</Color>
    </Cambio_Color_Preferencial>
    </soap:Body>
    </soap:Envelope>`;

  const postColorWS: WebService<string> = {
    url: urlPostColor,
    name: 'Cambio_Color_Preferencial',
    resultSelector: 'Cambio_Color_PreferencialResult',
    sr,
    transform: (res) => res,
  };

  return post(postColorWS);
}

export const getUsoNF = async (rut: string) => {
  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
    <Usar_Monto_No_Facturable xmlns="http://infrasistemas.sytes.net:7080/">
      <RUT>${rut}</RUT>
    </Usar_Monto_No_Facturable>
    </soap:Body>
    </soap:Envelope>`;

  const getUsarMontoNFWS: WebService<boolean> = {
    url: urlGetUsarMontoNF,
    name: 'Usar_Monto_No_Facturable',
    resultSelector: 'Usar_Monto_No_FacturableResult',
    sr,
    transform: (res) => (res === 'Sí') ? true : false
    ,
  };
  
  return post(getUsarMontoNFWS)
}

export const postUsoNF = async (rut: string, usoNF: boolean) => {
  const valor = usoNF ? 'Sí' : 'No';

  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
    <Cambio_Monto_No_Facturable xmlns="http://infrasistemas.sytes.net:7080/">
      <RUT>${rut}</RUT>
      <Valor>${valor}</Valor>
    </Cambio_Monto_No_Facturable>
    </soap:Body>
    </soap:Envelope>`;

  const postUsarMontoNFWS: WebService<string> = {
    url: urlPostUsarMontoNF,
    name: 'Cambio_Monto_No_Facturable',
    resultSelector: 'Cambio_Monto_No_FacturableResult',
    sr,
    transform: (res) => res,
  };
  
  return post(postUsarMontoNFWS)
}