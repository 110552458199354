import React, { ChangeEvent, useState } from 'react';

// ** Store
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { updateRemaining } from 'store/docs';

// ** antd
import { Form, Input, Button, Modal, Row, Col } from 'antd';
import { Recibo_CAE } from 'api/postDocument';

const FormReciboCAE = () => {
    // ** State
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)

    // ** Store
    const dispatch = useDispatch()
    const { rut } = useSelector(({ app }: RootState) => app);

    // ** Hooks
    const [form] = Form.useForm()

    const error = Modal.error;
    const success = Modal.success

    // ** Effect

    // ** handlers
    const onFinish = () => {
        const files = document.getElementById('xml') as HTMLFormElement

        const file = files.files[0]

        const reader = new FileReader()
        reader.onload = () => {
            const xml = reader.result as string
            if (rut) {
                Recibo_CAE(rut, xml)
                    .then(res => {
                        if (res === 'OK') {
                            dispatch(updateRemaining()); // update remaining CAEs
                            success({
                                content: 'Procedimiento correcto'
                            })
                            form.setFieldsValue({
                                xml: ''
                            }) // Reset file
                            setButtonDisabled(true) // disable button Enviar
                        } else {
                            error({
                                content: res
                            })
                        }
                    })
                    .catch(err => error({
                        content: err.message
                    }))
            }
        }

        if (file) {
            if (file.type === 'text/xml') {
                reader.readAsText(file)
            } else {
                error({
                    content: 'Seleccione un archivo XML'
                })
            }
        } else {
            error({
                content: 'No se encontró el archivo'
            })
        }
    }

    const onChange = (v: ChangeEvent<HTMLInputElement>) => {
        setButtonDisabled(Boolean(!v.target.value))
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Row justify="space-between" gutter={32} >
                <Col xl={12} xs={24} >
                    <Form.Item
                        name='xml'
                        label='Importar CAE'>
                        <Input
                            type='file'
                            accept='.xml'
                            onChange={onChange}
                        />
                    </Form.Item>
                </Col>
                <Col xl={12} xs={24}>
                    <Button htmlType='submit' disabled={buttonDisabled}>Enviar</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default FormReciboCAE