export const departamentos = [
  'Artigas',
  'Canelones',
  'Cerro largo',
  'Colonia',
  'Durazno',
  'Flores',
  'Florida',
  'Lavalleja',
  'Maldonado',
  'Montevideo',
  'Paysandu',
  'Rio negro',
  'Rivera',
  'Rocha',
  'Salto',
  'San jose',
  'Soriano',
  'Tacuarembo',
  'Treinta y tres',
];
